import { useEffect, useState } from "react";

import "./App.css";

const apiUrl = "https://api.costaelr.retter.io";

function App() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordSet, setPasswordSet] = useState(false);

  const searchParams = new URLSearchParams(document.location.search);
  const email = searchParams.get("email") || '';

  useEffect(() => {
    const needsEmailVerification = searchParams.get("needsEmailVerification");
    if (needsEmailVerification === "true") {
      getVerifyEmail();
    }
  }, []);

  useEffect(() => {
    const valid = Object.values(rules).every((rule) => rule.validator());
    setIsValid(valid);
    if (password === confirmPassword) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [password]);

  useEffect(() => {
    if (password === confirmPassword) {
      return setIsFormValid(true);
    }
    setIsFormValid(false);
  }, [confirmPassword]);

  const rules = {
    oneUpperCase: {
      validator: () => /[A-Z]/.test(password),
      text: "One uppercase",
    },
    oneLowerCase: {
      validator: () =>
        /[a-z]/.test(password) && password && password.password !== 0,
      text: "One lowercase",
    },
    min8Chars: {
      validator: () => password?.length >= 8,
      text: "Min 8 characters",
    },
    oneSpecialChar: {
      // eslint-disable-next-line no-useless-escape
      validator: () => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password),
      text: "One special character",
    },
    oneNumber: {
      validator: () => /[0-9]/.test(password),
      text: "One number",
    },
  };

  const getVerifyEmail = async () => {
    try {
      const token = searchParams.get("token");
      const projectId = searchParams.get("projectId");
      const instanceId = searchParams.get("instanceId");

      if (token && projectId && instanceId) {
        const params = {
          token,
        };
        const response = await fetch(
          `${apiUrl}/${projectId}/CALL/User/verifyEmailForExternalUser/${instanceId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          }
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      setErrorText(error?.message);
    } finally {
      setTimeout(() => {
        setErrorText("");
      }, 4000);
    }
  };

  const setPasswordRequest = async () => {
    try {
      const projectId = searchParams.get("projectId");
      const instanceId = searchParams.get("instanceId");
      setLoading(true);
      const params = {
        token: searchParams.get("token"),
        password,
        passwordAgain: confirmPassword,
      };

      const response = await fetch(
        `${apiUrl}/${projectId}/CALL/User/setPassword/${instanceId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      const data = await response.json();
      setLoading(false);

      if (!response.ok) {
        throw new Error(data.message);
      }

      setPasswordSet(true);
    } catch (error) {
      setPasswordSet(false);
      setErrorText(error?.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setErrorText("");
      }, 4000);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPasswordRequest();
  };

  const renderValidationTextProps = (validation) => {
    if (!password) {
      return { backgroundColor: "white", color: "#222322" };
    }
    if (validation()) {
      return {
        backgroundColor: "#4ca239",
        borderColor: "#4ca239",
        color: "white",
      };
    } else {
      return {
        backgroundColor: "#c80000",
        borderColor: "#c80000",
        color: "white",
      };
    }
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const showConfirmPasswordHandler = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="App">
      <div className="App-inner">
        <div className="App-content">
          <h1 className="title">Create Password</h1>
          <div className="mainDiv">
            <div className="cardStyle">
              {!passwordSet ? (
                <form onSubmit={handleSubmit} name="signupForm" id="signupForm">
                  <div className="inputDiv">
                    <span className="inputLabel" style={{ marginTop: "4px" }}>
                      Enter a new password {email ? `for ${email}` : ''}
                    </span>
                  </div>

                  <div className="inputDiv">
                    <label className="inputLabel" htmlFor="password">
                      New Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      onChange={handlePasswordChange}
                      value={password}
                      required
                    />
                    <div
                      className="showPasswordContainer"
                      onClick={showPasswordHandler}
                    >
                      {showPassword ? (
                        <img
                          src={"./openEye.svg"}
                          className="App-logo"
                          alt="logo"
                        />
                      ) : (
                        <img
                          src={"./closeEye.svg"}
                          className="App-logo"
                          alt="logo"
                        />
                      )}
                    </div>
                  </div>

                  <div className="inputDiv">
                    <label className="inputLabel" htmlFor="confirmPassword">
                      Confirm Password
                    </label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={handleConfirmPasswordChange}
                      value={confirmPassword}
                      required
                    />
                    <div
                      className="showPasswordContainer"
                      onClick={showConfirmPasswordHandler}
                    >
                      {showConfirmPassword ? (
                        <img
                          src={"./openEye.svg"}
                          className="App-logo"
                          alt="logo"
                        />
                      ) : (
                        <img
                          src={"./closeEye.svg"}
                          className="App-logo"
                          alt="logo"
                        />
                      )}
                    </div>
                  </div>

                  <div className="badgeContainer">
                    {Object.values(rules).map((rule, index) => {
                      return (
                        <span
                          key={index}
                          className="badge"
                          style={renderValidationTextProps(rule.validator)}
                        >
                          {rule.text}
                        </span>
                      );
                    })}
                  </div>

                  <div className="buttonWrapper">
                    <button
                      type="submit"
                      id="submitButton"
                      disabled={!isValid || !isFormValid || loading}
                      className="submitButton pure-button pure-button-primary"
                    >
                      <span>Change</span>
                      {loading ? <span id="loader"></span> : null}
                    </button>
                  </div>
                  <div>
                    {errorText ? (
                      <span className="errorText">{errorText}</span>
                    ) : null}
                  </div>
                </form>
              ) : (
                <div>
                  <div className="inputDiv">
                    <span className="successTitle">Success.</span>
                    <span
                      className="inputLabel"
                      style={{ fontSize: "16px", marginTop: "8px" }}
                    >
                      Your password has been set. 🔐
                    </span>
                    <span
                      className="inputLabel"
                      style={{ fontSize: "14px", marginTop: "24px" }}
                    >
                      You can download Costa Coffee Club UAE mobile app from
                      here <br />
                      <br /> (link:{" "}
                      <a href="https://onelink.to/3jhb9b">
                        https://onelink.to/3jhb9b
                      </a>
                      ). <br /> <br />
                      Please download it and login with your credentials.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="footerContainer">
          <a href="https://www.costacoffee.ae/" target="_blank">
            <img src={"./logo.png"} className="App-logo" alt="logo" />
          </a>
          <span className="footerText">
            Having an issue, please contact us form Support Costa Coffee Club
            UAE
          </span>
          <span className="footerText">
            <a href="mailto:support@costacoffeeclubuae.ae">
              support@costacoffeeclubuae.ae
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
